@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Roboto-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Roboto-medium';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Roboto-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
}
