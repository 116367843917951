.ats-popup-container.mapboxgl-popup {
    max-width: 450px !important;
}

.ats-popup-container .mapboxgl-popup-tip {
    display: none;
}

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -webkit-box-shadow: 4px 1px 32px -2px rgba(0,0,0,0.75)!important;
    -moz-box-shadow: 4px 1px 32px -2px rgba(0,0,0,0.75)!important;
    box-shadow: 4px 1px 32px -2px rgba(0,0,0,0.75)!important;
}
.gu-transit {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
}

.gu-transit :nth-child(1) {
    opacity: 0
}

.circle {
    border-radius: 25px!important;
}
.circle.size2 {
    width: 6px;
    height: 6px;
    margin-right: 10px;
    margin-left: 5px;
}

.circle.size3, .circle.size4 {
    width: 12px;
    height: 12px;
    margin-right: 7px;
    margin-left: 2px;
}

.mapboxgl-popup-close-button {
    font-size: 20px;
}